import * as React from "react"
import { Helmet } from 'react-helmet'
import Image from "../../components/image"

// markup
const IndexPage = () => {

  return (

    <>
      <Helmet title="Sports Direct - London Oxford Street - Floor 0" defer={false} />
      <Image image="/oxford-st/floor-0.png"/>
    </>

  )
}

export default IndexPage
